<template>
  <div class="container">
    <div class="main">
      <div class="top">
        <img src="../../../static/img/top_03.jpg" class="top" alt="">
      </div>
      <div class="link">
        <van-cell center class="link_item" :to="{name:'One'}" value="" is-link>
          <template #title>
            <div class="left">
              <img src="../../../static/img/8.png" alt="">
              <div class="info">
                <h2>工业园区</h2>
                <p>易安捷第一办公区</p>
              </div>
            </div>
          </template>
        </van-cell>
        <van-cell center class="link_item" :to="{name:'Three'}" value="" is-link>
          <template #title>
            <div class="left">
              <img src="../../../static/img/9.png" alt="">
              <div class="info">
                <h2>易安捷总公司</h2>
                <p>易安捷第三办公区</p>
              </div>
            </div>
          </template>
        </van-cell>
        <van-cell center class="link_item" :to="{name:'Four'}" value="" is-link>
          <template #title>
            <div class="left">
              <img src="../../../static/img/10.png" alt="">
              <div class="info">
                <h2>金泉办公区</h2>
                <p>易安捷第四办公区</p>
              </div>
            </div>
          </template>
        </van-cell>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  created() {
    this.$store.dispatch('changeLocation','路线指引')
    // if(window.history.length == 1 || sessionStorage.guide == true){
    //   sessionStorage.guide = true;
    //   this.$store.dispatch('changeNav',false)
    // }else{
    //   this.$store.dispatch('changeNav',true)
    // }
    if(window.history.length == 1) {
      this.$store.dispatch('changeNav', false)
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.main{
  padding-bottom: 50px;
  .top{
    img{
      display: block;
      width: 100%;
    }
  }
  .link{
    padding-top: 10px;
    .link_item{
      width: 350px;
      height: 78px;
      background-color: #ffffff;
      box-shadow: 0px 0px 7px 0px
      rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      margin: 0 auto 20px;
      &:last-child{
        margin-bottom: 0;
      }
      .left{
        flex: 1;
        display: flex;
        align-items: center;
        img{
          width: 60px;
          height: 60px;
          display: block;
          margin-right: 10px;
        }
        .info{
          h2{
            font-family: PingFang-SC-Bold;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #343434;
            margin: 0;
          }
          p{
            font-family: PingFang-SC-Medium;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #999999;
            margin: 0;
          }
        }
      }
    }
  }
}
::v-deep .van-cell__value{
  flex: none;
}
::v-deep .van-nav-bar__arrow{
  font-size: 19px;
  color: #666666;
}
::v-deep .van-nav-bar__text{
  font-family: PingFang-SC-Medium;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
}
</style>
